<template>
  <div class="flex swipeout-box">
    <div class="swipeout-action bg-cl-grey-smoke brdr-1 brdr-cl-white" v-if="action === 'remove'" @click="updateQuantity(product.qty === 1 ? 1 : product.qty -=1); closeSlots();">
      <span class="fs-big">-</span>
    </div>
    <div class="swipeout-action bg-cl-grey-smoke brdr-1 brdr-cl-white" v-if="action === 'add'" @click="updateQuantity(product.qty === 99 ? 99 : product.qty +=1); closeSlots();">
      <span class="fs32">+</span>
    </div>
    <div class="swipeout-action bg-cl-grey-smoke brdr-1 brdr-cl-white" @click="removeItem" v-if="action === 'delete'">
      <img :src="'/assets/svg/delete.svg'" width="32px" height="32px" alt="delete">
    </div>
  </div>
</template>

<script>
import config from 'config'
import i18n from '@vue-storefront/i18n'

export default {
  components: {
  },
  props: {
    action: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      displayItemDiscounts: config.cart.displayItemDiscounts,
      newValue: this.product.qty
    }
  },
  methods: {
    removeItem () {
      if (config.cart.askBeforeRemoveProduct) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          item: this.product,
          message: i18n.t('Are you sure you would like to remove this item from the shopping cart?'),
          action2: { label: i18n.t('OK'), action: this.removeFromCart },
          action1: { label: i18n.t('Cancel'), action: 'close' },
          hasNoTimeout: true
        })
      } else {
        this.removeFromCart()
      }
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    updateQuantity (quantity) {
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity })
    },
    closeSlots () {
      this.$emit('closeSlots', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.fs32 {
  font-size: 32px;
}
</style>
