<template>
  <li class="row flex-nowrap p10 m0 relative">
    <div class="bg-cl-secondary loader-box flex" v-if="loader">
      <loading-spinner />
    </div>
    <div class="w-28 flex image-product">
      <div class="image-box">
        <img class="image" v-lazy="thumbnail" alt="">
      </div>
    </div>
    <div class="flex px15 start-xs between-sm details w-40 title-product">
      <div>
        <div class="h4 title-font name cl-black weight-700 uppercase">
          {{ product.name | htmlDecode }}
        </div>
        <div class="properties-box">
          <div class="h5 cl-accent options" v-if="product.totals && product.totals.options">
            <div v-for="opt in product.totals.options" :key="opt.label">
              <span class="opn">{{ $t(opt.label) }}: </span>
              <span class="opv" v-html="opt.value" />
            </div>
            <p v-if="product.delivery_time <= 3 || isEcommerceProduct" class="m0 h5 cl-accent options">Dostępność: <span class="cl-forest-green"> w magazynie</span></p>
            <p v-else class="m0 h5 cl-accent options">{{ $t('Czas realizacji') }}: {{ product.delivery_time }} dni</p>
          </div>
          <div class="h5 cl-accent options" v-else-if="product.options">
            <div v-for="opt in product.options" :key="opt.label" class="my5">
              <span class="opn">{{ $t(opt.label) }}: </span>
              <spanclass="opv" v-html="opt.value" />
            </div>
            <p v-if="product.delivery_time <= 3 || isEcommerceProduct" class="m0 h5 cl-accent options">Dostępność: <span class="cl-forest-green"> w magazynie</span></p>
            <p v-else class="m0 h5 cl-accent options">{{ $t('Czas realizacji') }}: {{ product.delivery_time }} dni</p>
          </div>
          <div class="h6 mt5 cl-error" v-if="product.errors && Object.keys(product.errors).length > 0">
            {{ product.errors | formatProductMessages }}
          </div>
          <div class="h4 pt5 cl-accent single-price">
            <span class="">
              {{ product.qty }} x {{ price.default / product.qty | price(storeView) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex pl15 align-right start-xs between-sm actions w-32">
      <div class="links" :class="{ 'hidden-xs' : !isEdge }">
        <div class="ff-semi" @click="removeItem">
          <remove-button />
        </div>
      </div>
      <div class="h5 cl-accent lh25 qty flex-center">
        <number-input
          class="ff-light"
          :class="{ 'hidden-xs' : !isEdge }"
          :value="product.qty"
          @click="updateQuantity"
          @blur="updateQuantity"
        />
      </div>
      <div class="prices 1">
        <span class="h6 price-original" v-if="(price.special && price.default) && price.special !== price.default">
          {{ price.default | price(storeView) }}
        </span>
        <span class="h3 serif cl-error price-special" v-if="(price.special && price.default && price.original)
          && price.special !== price.default">
          {{ price.special | price(storeView) }}
        </span>
        <span class="h3 serif price-regular" v-else>
          {{ price.default | price(storeView) }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { getValueFor } from '@vue-storefront/core/helpers'
import LoadingSpinner from 'theme/components/core/LoadingSpinner.vue'
import rootStore from '@vue-storefront/core/store'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'
import RemoveButton from './RemoveButton'
import NumberInput from 'theme/components/core/NumberInput'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getThumbnailForProduct, getProductConfiguration } from '@vue-storefront/core/modules/cart/helpers'
import { getCustomOptionValues, getCustomOptionPriceDelta } from '@vue-storefront/core/modules/catalog/helpers/customOption'
import { getBundleOptionsValues, getBundleOptionPrice } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import get from 'lodash-es/get'

export default {
  components: {
    RemoveButton,
    NumberInput,
    LoadingSpinner
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mixins: [Product, ProductOption, BrowserDetected],
  data () {
    return {
      displayItemDiscounts: rootStore.state.config.cart.displayItemDiscounts,
      loader: false
    }
  },
  computed: {
    getCurrentCustomOptions () {
      let custom_options = this.product.product_option.extension_attributes.custom_options
        ? this.product.product_option.extension_attributes.custom_options
        : null
      if (!custom_options) return {}
      let currentCustomOptions = {}
      for (let option of custom_options) {
        currentCustomOptions[option.option_id] = option
      }
      return custom_options
    },
    isEcommerceProduct () {
      return this.product.configurable_children
        ? this.product.configurable_children.some(element => {
          return element.stock
            ? (this.product.ecommerce === getValueFor('ecommerce', 'yes') && element.stock.qty > 0) || (this.product.ecommerce === getValueFor('ecommerce', 'both') && element.stock.qty > 0)
            : false
        })
        : false
    },
    thumbnail () {
      return getThumbnailForProduct(this.product)
    },
    storeView () {
      return currentStoreView()
    },
    bundleOptionsPrice () {
      const allBundeOptions = this.product.bundle_options || []
      const selectedBundleOptions = Object.values(get(this.product, 'product_option.extension_attributes.bundle_options', {}))
      const price = getBundleOptionPrice(
        getBundleOptionsValues(selectedBundleOptions, allBundeOptions)
      )
      return price
    },
    customOptionsPriceDelta () {
      const priceDelta = this.product.product_option.extension_attributes && this.product.product_option.extension_attributes.custom_options
        ? getCustomOptionPriceDelta(getCustomOptionValues(Object.values(this.product.product_option.extension_attributes.custom_options), this.product.custom_options), this.product)
        : getCustomOptionPriceDelta(getCustomOptionValues(Object.values(this.getCurrentCustomOptions), this.product.custom_options), this.product)
      return priceDelta
    },
    price () {
      const discountAmount = this.product.totals && this.product.totals.discount_amount
      const customOptionPrice = this.customOptionsPriceDelta.priceInclTax
      const special = discountAmount
        ? (this.initialPrice.default + customOptionPrice) * this.product.qty - discountAmount
        : (this.initialPrice.default + customOptionPrice) * this.product.qty
      const original = (this.initialPrice.original + customOptionPrice) * this.product.qty
      const defaultPrice = this.product.qty > 0
        ? (this.initialPrice.default + customOptionPrice) * this.product.qty
        : this.initialPrice.default

      if (this.bundleOptionsPrice.priceInclTax > 0) {
        return {
          special,
          original,
          default: this.bundleOptionsPrice.priceInclTax
        }
      }

      return {
        special,
        original,
        default: defaultPrice
      }
    },
    initialPrice () {
      return {
        default: this.product.priceInclTax || 0,
        original: this.product.originalPriceInclTax || 0,
        special: this.product.specialPrice || 0
      }
    }
  },
  methods: {
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    changeLoader (bool) {
      this.loader = bool
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.6;
}
.image-product {
  .image-box {
    display: flex;
    align-items: center;
  }
  @media (max-width: 767px) {
    width: 20% !important;
  }
}
.title-product {
  @media (max-width: 767px) {
    width: 60% !important;
    padding-right: 0;
    justify-content: center;
  }
}
 @media (min-width: 768px) {
  .title-product {
    justify-content: center;
  }
  .actions {
    justify-content: space-around;
  }
  .h3-sm {
    font-size: 24px;
  }
 }
.image {
  mix-blend-mode: multiply;
  vertical-align: top;
  width: 150px;
  height: 150px;
  @media (max-width: 767px) {
    width: 65px;
    height: 65px
  }
}
.w-40 {
  width: 40%;
}
.w-28 {
  width: 28%;
}
.w-32 {
  width: 32%;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
  .details {
    flex-direction: column;
    @media (max-width: 767px) {
      // padding: 0 10px 0 20px;
    }
  }
  .name {
    height: 38px;
    overflow: hidden;
    @media (max-width: 767px) {
      font-size: 14px;
      height: 30px;
    }
  }
  .options, .sku {
    @media (max-width: 767px) {
      div {
        margin: 0
      }
      font-size: 12px;
    }
  }
  .qty {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .actions {
    flex-direction: column;
    @media (max-width: 767px) {
      justify-content: flex-end;
      width: 20%;
      padding-left: 0;
      font-size: 12px;
    }
    .links {
      @media (min-width: 768px) {
        display: block;
      }
      @media (max-width: 767px) {
        font-size: 0;
      }
    }
  }
  .price-special {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .price-original {
    text-decoration: line-through;
  }
  .price-regular {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  input {
    width: 30px;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .single-price {
    @media (max-width: 767px) {
      font-size: 12px;
      color: #707070;
      // margin-left: 25px;
    }
  }
  .properties-box {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      // margin-bottom: 10px;
    }
  }
</style>
