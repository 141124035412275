<template>
  <div id="number-input">
    <div class="counter">
      <i class="material-icons" @click="$emit('click', decreaseValue)">remove</i>
      <input
        type="number"
        min="0"
        max="99"
        :value="value"
        @change="update(newValue)"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      >
      <i class="material-icons" @click="$emit('click', increaseValue)">add</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      newValue: this.value,
      loader: false
    }
  },
  watch: {
    newValue (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    setLoader () {
      this.loader = true
      this.$emit('isPriceLoad', this.loader)
      setTimeout(() => {
        this.loader = false
        this.$emit('isPriceLoad', this.loader)
      }, 7000)
    },
    update (val) {
      this.setLoader()
      this.newValue = val
    }
  },
  computed: {
    decreaseValue () {
      this.setLoader()
      var newValue = this.value
      if (this.value < 2) {
        newValue = 1
      } else {
        newValue -= 1
      }
      return newValue
    },
    increaseValue () {
      this.setLoader()
      var newValue = this.value
      if (newValue < 99) {
        newValue += 1
      } else {
        newValue = 99
      }
      return newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .counter {
    input[type="number"] {
      height: 37px !important;
      width: 37px !important;
      font-size: 22px !important;
    }
    i.material-icons {
      font-size: 26px !important;
      height: 37px !important;
      width: 37px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
div#number-input {
  // margin-bottom: 1em;
  .counter {
    display: flex;
    background: white;
    border: 1px solid #C6C6C6;
    align-items: center;
    // border-radius: 30px;
    input[type="number"] {
      border-left: 1px solid #C6C6C6 !important;
      border-right: 1px solid #C6C6C6 !important;
      border: 0;
      text-align: center;
      height: 30px;
      padding: 0;
      font-size: 22px;
      width: 30px;
      color: #707070;
      border-radius: 0;
    }
    input[type="number"]:focus,
    i:focus {
      outline: none;
    }
    i.material-icons {
      padding: 0;
      color: #707070;
      font-size: 28px;
      text-align: center;
      height: 30px;
      width: 28px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

@media (max-width: 767px) {
  div#number-input {
    .number {
      padding: 5px 15px;
    }
    i.material-icons.delete-number {
      padding: 0 15px 0 0 !important;
      height: 40px;
    }
    .counter input[type="number"] {
      // height: 40px;
      // width: 40px;
      box-sizing: border-box;
    }
    .counter i.material-icons {
      padding: 0;
    }
  }
}

@media (max-width: 340px) {
  div#number-input {
    .number {
      padding: 5px 10px;
    }
    i.material-icons.delete-number {
      width: 40px;
    }
    .counter i.material-icons {
      padding: 0 4px;
    }
  }
}
</style>
